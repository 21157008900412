import React from 'react'
import { css } from '@emotion/core'

export default (props) => (
    <header
        css={css`
            padding: 2.5em 0;
            max-width: 56em;
            margin-right: auto;
            margin-left: auto;
            text-align: center;
            @media ( min-width: 48em ) {
                padding-top: 4.5em;
            }

        `}
    >
        <h1
            css={css`
                font-size: 1.875em;
                line-height: 1.2;
                margin: 0;
                @media ( min-width: 48em ) {
                    font-size: 3em;
                    line-height: 1;
                }
            `}
        >{props.title}</h1>
        {props.excerpt &&
            <div
                dangerouslySetInnerHTML={{ __html: props.excerpt }}
                css={css`
                    p {
                        font-size: 1.0625em;
                    }
                    @media ( min-width: 48em ) {
                        p {
                            font-size: 1.25em;
                        }
                    }
                `}
            />
        }
    </header>
)
