import React from 'react'
import { css } from '@emotion/core'
import { useStaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default () => {
    const data = useStaticQuery(
        graphql`
            query {
                badge: file(relativePath: { eq: "images/ialds.png" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        `
    )

    return (
        <footer
            css={css`
                position: relative;
                display: flex;
                padding: 2.5em 0 1em;
                background-color: var(--color-dark);
                color: var(--color-light);
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 100vw;
                    left: 50%;
                    margin-left: -50vw;
                    background-color: inherit;
                }
                section {
                    position: relative;
                    h3 {
                        font-family: var(--font-wide);
                        font-size: 0.75em;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                    }
                    ul {
                        list-style: none;
                        margin: 1em 0;
                        padding: 0;
                        li:not(:last-child) {
                            margin-bottom: 0.375em;
                        }
                        a {
                            color: inherit;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                @media ( max-width: 48em ) {
                    flex-direction: column;
                    section {
                        order: 1;
                        margin-bottom: 1.5em;
                    }
                    section:last-of-type {
                        order: 0;
                        margin-bottom: 2.5em;
                    }
                    cite {
                        order: 2;
                    }
                }
                @media ( min-width: 48em ) {
                    justify-content: space-between;
                    flex-wrap: wrap;
                    padding: 4em 0 1.5em;
                    section {
                        width: calc(25% - 1.5em);
                    }
                }
            `}
        >
            <section>
                <a href="https://ialds.org" target="_blank" title="Visit IALDS.org">
                    <Img
                        fluid={data.badge.childImageSharp.fluid}
                        alt="International Association of Learner-Driven Schools Accredited"
                        css={css`
                            max-width: 6em;
                            filter: saturate(0);
                            box-shadow: 0 0 0 3px var(--color-light);
                            border-radius: 9px;
                            transition: filter 0.2s ease;
                            &:hover,
                            &:active {
                                filter: saturate(1);
                            }
                        `}
                    />
                </a>
            </section>
            <section>
                <h3>Overview</h3>
                <ul>
                    <li><Link to="/mission">Mission</Link></li>
                    <li><Link to="/approach">Approach</Link></li>
                    <li><Link to="/readings">Get Inspired</Link></li>
                    <li><Link to="/faqs">FAQs</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </section>
            <section>
                <h3>Day to Day</h3>
                <ul>
                    <li><Link to="/a-day-at-acton">A Day at Acton</Link></li>
                    <li><Link to="/student-projects">Student Projects</Link></li>
                    <li><Link to="/campus">Campus</Link></li>
                </ul>
            </section>
            <section>
                <Link to="/admissions"
                    css={css`
                        display: inline-block;
                        vertical-align: center;
                        padding: 0.875em 1.25em;
                        line-height: 1em;
                        background-color: var(--color-bright);
                        color: var(--color-dark);
                        font-family: var(--font-wide);
                        font-size: 18px;
                        font-size: 1.125rem;
                        letter-spacing: 0.05em;
                        border-radius: 0.1em;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                            outline-width: 3px;
                            outline-style: auto;
                            outline-color: var(--color-highlight) !important;
                        }
                        @media ( min-width: 48em ) {
                            float: right;
                        }
                    `}
                >Admissions</Link>
            </section>

            <cite
                css={css`
                    position: relative;
                    display: block;
                    width: 100%;
                    border-top: 1px dashed rgba(255,255,255,0.3);
                    margin-top: 1.5em;
                    padding-top: 0.75em;
                    font-style: normal;
                    font-size: 0.75em;
                    letter-spacing: 0.025em;
                    span {
                        display: block;
                    }
                    a {
                        color: inherit;
                    }
                    @media ( min-width: 48em ) {
                        margin-top: 3em;
                        display: flex;
                        justify-content: space-between;
                    }
                `}
            >
                <span>Copyright 2019 Acton Academy Upstate</span>
                <span>Site by <a href="https://studiocedar.com" rel="nofollow" title="Studio Cedar" target="_blank">Studio Cedar</a></span>
            </cite>
        </footer>
    )
}
