import React from "react"
import Helmet from "react-helmet"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import Header from "../components/Header"
import Footer from './Footer'
import './layout.css'

export default ({ children }) => {
    return (
        <div
            css={css`
                position: relative;
                overflow: hidden;
            `}
        >
            <div
                css={css`
                    margin: 0 auto;
                    max-width: 89em;
                    padding-right: 1.5em;
                    padding-left: 1.5em;
                `}
            >
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" /> 
                    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin /> 
                    <link href="https://fonts.googleapis.com/css2?family=Archivo+Black&family=Source+Serif+Pro:wght@400;600;700&display=swap" rel="stylesheet" />
                </Helmet>
                <Header />
                {children}
                <Footer />
            </div>
        </div>
    )
}
