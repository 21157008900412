import React from 'react'
import { css } from '@emotion/core'

export default ({ children }) => (
    <div
        css={css`
            position: relative;
            padding: 1.5em 0 3.5em;
            max-width: 48em;
            margin-right: auto;
            margin-left: auto;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 100vw;
                margin-left: -50vw;
                background-color: #fff;
            }
            > * {
                position: relative;
            }
        `}
    >{children}</div>
)
