import React from "react"
import { css } from "@emotion/core"
import { useStaticQuery, Link, graphql } from "gatsby"
import Logo from '../components/Logo'
import Button from './Button'

const Notice = props => (
    <aside
        css={css`
            position: relative;
            z-index: 1;
            background-color: var(--color-highlight);
            margin: 0;
            padding: 1.5em 0;
            color: var(--color-bg);
            &::before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 100vw;
                margin-left: -50vw;
                background-color: inherit;
            }
            p {
                max-width: 48em;
                margin: 0 auto;
                text-align: center;
            }
        `}
    >
        <p><strong>Thank you for your interest in Acton Upstate.</strong> Due to the ongoing pandemic and cost of building materials we will not be opening our campus this Fall. We hope the situation improves enough that we can reassess in Spring 2022. Please check back at that time if you are still interested.</p>
    </aside>
)

const ListLink = props => (
    <li
        css={css`
            a {
                color: inherit;
                text-decoration: none;
                font-size: 1.125em;
                font-family: var(--font-serif);
                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        `}
    >
        {
            ! props.to.match(/^http/)
            ?
            <Link
                to={props.to}
                activeStyle={{ textDecoration: 'underline' }}
            >{props.children}</Link>
            :
            <a href={props.to} target="_blank" rel="nooppener noreferrer">{props.children}</a>
        }
    </li>
)
export default ({ children }) => {
    /*const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                    }
                }
                wordpressMenusMenusItems(slug: { eq: "primary" }) {
                    items {
                        title
                        url
                        target
                        menu_item_parent
                        classes
                        attr_title
                    }
                }
            }
        `
    )*/
    return (
        <>
        <Notice />
        <header
            css={css`
                position: relative;
                padding: 1em 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @media ( max-width: 48em ) {
                    input:checked + label span {
                        background-color: transparent;
                    }
                    input:checked + label span::before {
                        top: 0px;
                        width: 100%;
                        transform: rotate(45deg);
                    }
                    input:checked + label span::after {
                        top: 0px;
                        width: 100%;
                        transform: rotate(-45deg);
                    }
                    input:checked ~ nav {
                        opacity: 1;
                        transform: translate3d(0,0,0);
                        right: 0;
                        transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.1s ease, right 0s linear 0s;
                    }
                }
                @media ( min-width: 48em ) {
                }
            `}
        >
            <Link to="/" css={css`
                display: block;
                width: calc(100% - 4em);
                max-width: 20em;
                @media ( max-width: 48em ) {
                    margin-left: -1em;
                }
                @media ( min-width: 48em ) {
                    max-width: 22em;
                }
            `}>
                <Logo />
            </Link>
            <input
                type="checkbox"
                id="nav-toggle"
                css={css`
                    position: absolute;
                    left: -99999em;
                    opacity: 0;
                `}
            />
            <label
                for="nav-toggle"
                css={css`
                    position: relative;
                    display: block;
                    width: 2.5em;
                    height: 2.5em;
                    @media ( min-width: 48em ) {
                        display: none;
                    }
                `}
            ><span
                css={css`
                    position: relative;
                    display: block;
                    width: 50%;
                    top: calc(50% - 1.5px);
                    left: 25%;
                    height: 3px;
                    background-color: var(--color-bright);
                    position: relative;
                    z-index: 1000;
                    border-radius: 0.2em;
                    transition-duration: 250ms;
                    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
                    transition-delay: initial;
                    transition-property: all;
                    text-indent: -99999em;
                    &::before {
                        content: "";
                        top: -7px;
                        width: calc(100% - 4px);
                        right: 0px;
                        border-radius: 0.2em;
                        height: 3px;
                        background-color: var(--color-bright);
                        position: absolute;
                        transition-duration: 250ms;
                        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                        transition-delay: initial;
                        transition-property: all;
                    }
                    &&::after {
                        content: "";
                        top: 7px;
                        width: calc(100% - 8px);
                        right: 0px;
                        border-radius: 0.2em;
                        height: 3px;
                        background-color: var(--color-bright);
                        position: absolute;
                        transition-duration: 250ms;
                        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
                        transition-delay: initial;
                        transition-property: all;
                    }
                `}
            >Menu</span></label>
            <nav
                css={css`
                    @media ( max-width: 48em ) {
                        position: absolute;
                        z-index: 1000;
                        top: calc(100% - 1em);
                        right: -100vw;
                        max-width: 100%;
                        background-color: var(--color-bright);
                        color: var(--color-bg);
                        border-radius: 0.1em;
                        box-shadow: 0 1px 8px 1px rgba(0,0,0,0.2);
                        opacity: 0;
                        transform: translate3d(0,-0.5em,0);
                        transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s ease 0.1s, right 0s linear 0.3s;
                    }
                `}
            >
                <ul
                    css={css`
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        @media ( max-width: 48em ) {
                            padding: 1.5em;
                            & a {
                                display: block;
                                padding: 0.5em;
                            }
                        }
                        @media ( min-width: 48em ) {
                            display: flex;
                            align-items: center;
                            li:not(:last-child) {
                                margin-right: 1em;
                            }
                            @media ( min-width: 60em ) {
                                li:not(:last-child) {
                                    margin-right: 2em;
                                }
                            }
                        }
                    `}
                >
                    <ListLink to="/mission">Mission</ListLink>
                    <ListLink to="/approach">Approach</ListLink>
                    <ListLink to="/a-day-at-acton">A Day at Acton</ListLink>
                    <ListLink to="/campus">Campus</ListLink>
                    <ListLink to="/contact">Contact</ListLink>
                    <ListLink to="/admissions"><Button>Admissions</Button></ListLink>

                    {/*data.wordpressMenusMenusItems.items.map(( item ) => (
                        <ListLink to={item.url}>{item.title}</ListLink>
                    ))*/}
                </ul>
            </nav>
        </header>
        </>
    )
}
