import React from 'react'
import { css } from "@emotion/core"

export default ({ children, props }) => (
    <span
        css={css`
            display: inline-block;
            vertical-align: center;
            padding: 0.875em 1.25em 0.75em;
            line-height: 1em;
            background-color: var(--color-highlight);
            color: #fff;
            font-family: var(--font-wide);
            font-size: 14px;
            font-size: 0.875rem;
            letter-spacing: 0.05em;
            border-radius: 0.1em;
            cursor: pointer;
            /*&:hover {
                box-shadow: 0 0 0 2px var(--color-bg), -4px 4px 0 0 var(--color-bright);
            }*/
            &:hover {
                text-decoration: underline;
                outline-width: 3px;
                outline-style: auto;
                outline-color: var(--color-highlight) !important;
            }
        `}
    >{children}</span>
)
